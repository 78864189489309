// ----------------
// SCENE
//
// Component File
// ----------------

@import "~styles/vars";

@shift-amount: 100px;

:local(.scene) {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: transform @base-transition-time ease-in-out;

  // allow pointer events to all children
  * {
    pointer-events: auto;
  }
}

:local(.loading) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @white;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 1.3em;
}

// :local(.shifted) {
//   composes: scene;
//   transform: translate3d(-@shift-amount, 0, 0);
// }
