// ----------------
// BRICK PICKER
//
// Component File
// ----------------

@import "~styles/vars";

@brick-height: 60px;
@tip-size: 7px;

:local(.brickPicker) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.brick) {
  color: @white;
  height: @brick-height;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

:local(.brickIcon) {
  height: 40px;
  // margin-right: @base-margin-small;
}

:local(.picker) {
  position: absolute;
  top: 35px;
  left: -(@base-margin / 2);
  padding: @base-padding;
  background: @primary;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid @primary;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: @base-margin;
  z-index: 2;

  @media screen and (min-width: @screen-md) {
    top: (@brick-height + @base-margin + 5px);
  }

  &:after {
    content: " ";
    position: absolute;
    top: -(@tip-size);
    left: (@base-margin * 5);
    border-left: @tip-size solid transparent !important;
    border-right: @tip-size solid transparent !important;
    border-bottom-width: @tip-size;
    border-bottom-style: solid;
    border-color: @primary;
  }
}

:local(.brickExample) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }
}

:local(.brickThumb) {
  height: 50px;
  margin-bottom: 12px;
  padding: @base-padding-small @base-padding;
  background-color: @white;
  border: 2px solid @white;
  border-radius: 10px;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.2);
  transition: all @base-transition-time ease-in-out;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  }
}

:local(.selected) {
  composes: brickThumb;
  background-color: transparent;
}

:local(.label) {
  color: @black;
  font-size: 0.8em;
}
