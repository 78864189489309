@import "~styles/vars";

:local(.saveSceneForm) {
  line-height: 1.5;
}

:local(.input) {
  width: 100%;
  padding: 0.75em 1.25em;
  border-radius: 3px;
  margin-bottom: @base-margin;
  box-sizing: border-box;
  border: 2px solid @tertiary;

  &:focus {
    outline: 1px solid @tertiary;
    // border-color: @primary;
  }

  &:invalid {
    border-color: @secondary;
  }

  &:focus:invalid {
    outline-color: @secondary;
  }
}

:local(.error) {
  color: @secondary;

  margin-bottom: @base-margin;
  text-align: center;
}

:local(.success) {
  text-align: center;
}

:local(.button) {
  margin: 0 auto;
}
