@import "~styles/vars";

@close-button-dimensions: 30px;

.modal {
  border: 0;
  padding: 0;
  border-radius: 10px;
  background: @primary;
  color: @tertiary;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .modalMd {
    composes: modal;
    max-width: 40vw;
  }

  .modalLg {
    composes: modal;
    max-width: 60vw;
  }

  .modalXl {
    composes: modal;
    max-width: 80vw;
  }
}

.modalContent {
  padding: 1rem;
  margin: 1rem;
  background: @primary;
  position: relative;

  @media screen and (min-width: @screen-md) {
    padding: 2rem;
    margin: 2rem;
  }
}

.modal::backdrop {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

:local(.closeButton) {
  position: absolute;
  top: -(@close-button-dimensions / 3);
  right: -(@close-button-dimensions / 3);
  width: @close-button-dimensions;
  height: @close-button-dimensions;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @white;
  color: @tertiary;
  transition: all @base-transition-time ease-in-out;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.15);
  border: none;
  appearance: none !important;

  &:hover {
    cursor: pointer;
    background: darken(@white, 10%);
  }
}
