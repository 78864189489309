@import "~styles/vars";

:local(.loader) {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-style: solid;
  border-color: #fff;
  border-bottom-color: @tertiary;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

:local(.loader--sm) {
  width: 16px;
  height: 16px;
  border-width: 3px
}

:local(.loader--md) {
  width: 32px;
  height: 32px;
  border-width: 4px;
}

:local(.loader--lg) {
  width: 64px;
  height: 64px;
  border-width: 7px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
