:local(.modalContent) {
  line-height: 1.5;
}

:local(.modalContent::backdrop) {
  background: hsl(0 0% 0% / 80%);
}

:local(.modalActions) {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

:local(.modalActionBtn) {
  // font-size: 0.75em;
  // position: absolute;
  // top: 0.25em;
  // right: 0.25em;
}
