@import "~styles/vars";

:local(.imgLogoContainer) {
  display: block;
  padding: 0.5em 0;
  text-align: center;
  width: 200px;
  max-width: 200px;
  margin: 0 auto;

  @media screen and (min-width: @screen-md) {
    margin: 0;
  }
}

:local(.imgLogo) {
  width: 100%;
  display: block;
}
