// ----------------
// STATS
//
// Component File
// ----------------

@import "~styles/vars";

@close-button-dimensions: 30px;

:local(.stats) {
  position: absolute;
  bottom: @base-margin;
  left: @base-margin;
}
:local(.iconWrapper) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: @white;
  transition: all @base-transition-time ease-in-out;
  padding: @base-padding-small @base-padding;
  background-color: fadeout(@tertiary, 10);
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: fadeout(@tertiary, 30);
  }
}
:local(.hidden) {
  display: none;
}
:local(.icon) {
  font-family: "ionicons";
  font-size: 1.5em;
  margin-bottom: (@base-margin-small / 2);

  @media screen and (min-width: @screen-md) {
    font-size: 2em;
  }
}

:local(.title) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  margin-bottom: 0.5em;

  @media screen and (min-width: @screen-md) {
    font-size: 1.2em;
  }
}

:local(.text) {
  font-weight: 700;
  font-size: 0.8em;
  text-align: center;
}
:local(.sumAllPrice) {
  composes: text;
}
:local(.line) {
  margin: 0 auto;
  margin-top: (@base-margin-small / 2);
  margin-bottom: (@base-padding-small / 2);
  height: 1px;
  width: 70%; /* or 100px */
  border-top: 1px solid fadeout(@white, 70);
}
:local(.detailsButton) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em auto;
  padding: 0.25em 0.5em;
  appearance: none;
  background: @primary;
  color: @tertiary;
  border-radius: 5px;
  border: none;
  font-size: 0.8em;
  font-weight: 700;
}

:local(.detailsIcon) {
  margin-left: (@base-margin-small / 2);
  display: inline-block;
  rotate: 45deg;
  font-family: "ionicons";
  font-size: 1.2em;
}

:local(.modalTitle) {
  text-align: center;
  margin: 0 0 1em;
}

:local(.totalRow) {
  font-weight: 700;
}
