// ----------------
// BRICK PICKER
//
// Component File
// ----------------

@import "~styles/vars";

@brick-height: 60px;
@tip-size: 7px;

:local(.modeSelector) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.mode) {
  color: @white;
  height: @brick-height;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
}

:local(.modeSelectorModal) {
  position: absolute;
  top: 35px;
  left: -(@base-margin / 2);
  padding: @base-padding;
  background: @primary;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid @primary;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: @base-margin;
  z-index: 2;

  @media screen and (min-width: @screen-md) {
    top: (@brick-height + @base-margin + 5px);
  }

  &:after {
    content: " ";
    position: absolute;
    top: -(@tip-size);
    left: @base-margin;
    border-left: @tip-size solid transparent !important;
    border-right: @tip-size solid transparent !important;
    border-bottom-width: @tip-size;
    border-bottom-style: solid;
    border-color: @primary;
  }
}


:local(.modeElem) {
  color: @tertiary;
  height: @brick-height;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5em 1em;

  &:hover {
    cursor: pointer;
  }
}

:local(.selectedMode) {
  composes: modeElem;

  background-color: @tertiary;
  color: @primary;
}
