// ----------------
// TOPBAR
//
// Component File
// ----------------

@import "~styles/vars";

@squareSize: 0.5em;

:local(.topBar) {
  position: absolute;
  top: 0;
  left: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  width: 100%;
  z-index: 9;
  background: @primary;
  transition: all @base-transition-time ease-in-out;
  border-bottom: 1px solid rgb(174 174 174 / 24%);
  box-shadow: 0px 0px 8px #7474743b;

  @media screen and (min-width: @screen-md) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

:local(.buttonSection) {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, min-content) 1fr repeat(2, min-content);
  width: 100%;
  transition: 300ms;
}

:local(.topBarMobileSlideOpen) {
  composes: topBar;

  @media screen and (max-width: @screen-md) {

    .buttonSection {
      grid-template-columns: repeat(16, 1fr);
    }

    .buttonContainer:nth-child(1) {
      grid-column: 3 / span 4;
    }

    .buttonContainer:nth-child(2) {
      grid-column: 7 / span 4;
    }

    .buttonContainer:nth-child(3) {
      grid-column: 11 / span 4;
    }

    .buttonContainer:nth-child(4) {
      grid-row: 2;
      grid-column: 1 / span 4;
    }

    .buttonContainer:nth-child(5) {
      grid-row: 2;
      grid-column: 5 / span 4;
    }

    .buttonContainer:nth-child(6) {
      grid-row: 2;
      grid-column: 9 / span 4;
    }

    .buttonContainer:nth-child(7) {
      grid-row: 2;
      grid-column: 13 / span 4;
    }

  }

  .mobileSlideTrigger i:before {
    transform: rotate(180deg);
  }
}

:local(.mobileSlideTrigger) {
  position: absolute;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  top: 99%;
  left: calc(50% - 1.25em);
  width: 2.5em;
  height: 1.5em;
  text-align: center;
  background: @primary;
  z-index: 2;
  color: @top-bar-color;
  border-radius: 0 0 0.5em 0.5em;
  font-size: 1.1em;
  box-shadow: 1px 5px 10px 0px #7474743b;

  i:before {
    transition: all @base-transition-time ease-in-out;
  }

  @media screen and (min-width: @screen-md) {
    display: none;
  }
}



:local(.buttonContainer) {
  &:nth-child(6) {
    grid-column: 7;
  }

  &:nth-child(7) {
    grid-column: 8;
  }
}


// TOP BAR BUTTON

:local(.button) {
  padding: (@base-padding / 2) @base-padding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: @top-bar-color;
  transition: all @base-transition-time ease-in-out;

  &:hover {
    color: @text-color-secondary;
    cursor: pointer;
  }

  @media screen and (min-width: @screen-md) {
    padding: @base-padding;
  }
}

:local(.active) {
  composes: button;
  color: @text-color-secondary;
  background: @secondary;
}

:local(.icon) {
  font-family: "ionicons";
  font-size: 1.1em;

  @media screen and (min-width: @screen-md) {
    font-size: 1.5em;
    margin-bottom: @base-margin-small;
  }
}

:local(.text) {
  display: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.65em;

  @media screen and (min-width: @screen-md) {
    display: block;
  }
}

:local(.topBarMobileSlideOpen) {

  .button {
    padding: @base-padding;
  }

  .text {
    display: block;
  }

  .icon {
    margin-bottom: @base-margin-small;
  }
}

