@import "~styles/vars";

:local(.kbd) {
  background-color: @tertiary;
  color: @primary;
  padding: 0.2em 1em;
  font-size: 0.8em;
  border-radius: 4px;
  font-weight: 700;
}

:local(.plus) {
  margin: 0 0.5em;
}
