@import (css) url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

@import "vars";

html,
body {
  height: 100%;
  width: 100%;
  font-family: @base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  overflow: hidden;
}

#canvas {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0.5em 1em;
  border: 1px solid fadeout(@tertiary, 60%);
}

:global(.sr-only) {
  clip: rect(0,0,0,0)!important;
  border: 0!important;
  height: 1px!important;
  margin: -1px!important;
  overflow: hidden!important;
  padding: 0!important;
  position: absolute!important;
  width: 1px!important;
}
