@import "~styles/vars";

:local(.formButton) {
  display: block;
  color: @primary;
  transition: all @base-transition-time ease-in-out;
  padding: 0.5em 1em;
  background-color: @tertiary;
  // font-size: 1.2em;
  border: 2px solid @tertiary;
  border-radius: 3px;

  > i {
    margin-right: @base-margin-small;
  }

  &:hover {
    cursor: pointer;
    color: @tertiary;
    border-color: @tertiary;
    background-color: @primary;
  }
}
