// ----------------
// HELP
//
// Component File
// ----------------

@import "~styles/vars";

@modal-width: 400px;
@modal-height: 500px;
@close-button-dimensions: 30px;

:local(.help) {
  position: absolute;
  bottom: @base-margin;
  right: @base-margin;
  z-index: 99;
}

:local(.triggerButton) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75em;
  color: darken(@tertiary, 10%);
  transition: color @base-transition-time ease-in-out;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;

  @media screen and (min-width: @screen-md) {
    font-size: 1.1em;
  }

  > span {
    display: none;

    @media screen and (min-width: @screen-md) {
      display: inline-block;
    }
  }

  > i {
    @media screen and (min-width: @screen-md) {
      margin-right: @base-margin-small;
    }
  }

  &:hover {
    cursor: pointer;
    color: darken(@tertiary, 30%);
  }
}

:local(.inversed) {
  composes: triggerButton;
  color: @white;

  &:hover {
    color: @tertiary;
  }
}

:local(.content) {
  line-height: 1.5;
  font-size: 0.8em;

  @media screen and (min-width: @screen-md) {
    font-size: 1em;
  }
}

:local(a) {
  color: @tertiary;
  transition: all @base-transition-time ease-in-out;

  &:hover {
    color: @secondary;
  }
}

:local(.title) {
  font-size: 1.5em;
  font-weight: 600;
  // margin-bottom: 0;
}

:local(.subtitle) {
  font-size: 1.2em;
  margin-bottom: (@base-margin / 2);
}

:local(.close) {
  position: absolute;
  top: -(@close-button-dimensions / 3);
  right: -(@close-button-dimensions / 3);
  width: @close-button-dimensions;
  height: @close-button-dimensions;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @white;
  color: @secondary;
  transition: all @base-transition-time ease-in-out;
  box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.15);

  &:hover {
    cursor: pointer;
    background: darken(@white, 10%);
  }
}

:local(.explanationSection) {
  display: block;
}

@media screen and (min-width: 768px) {
  :local(.explanationSection) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1em;
  }
}

:local(.section) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: (@base-padding / 2);
  margin-bottom: @base-margin-small;

  @media screen and (min-width: @screen-md) {
    margin-bottom: @base-margin;
    gap: @base-padding;
  }
}

:local(.icon) {
  color: @text-color-secondary;
  font-size: 2em;
}
:local(.imgCam) {
  width: 4em;
}
