// ----------------
// BUILDER
//
// Container File
// ----------------

@import "~styles/vars";

:local(.builder) {
  color: @black;
}
